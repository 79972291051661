var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "radio-box-container" },
    _vm._l(_vm.radioList, function (ref, key) {
      var label = ref.label
      var val = ref.value
      return _c(
        "el-radio",
        {
          key: key,
          attrs: { value: _vm.value, disabled: _vm.disabled, label: label },
          on: {
            input: function ($event) {
              return _vm.$emit("input", label)
            },
          },
        },
        [_vm._v(_vm._s(val))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }